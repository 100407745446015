import {
  Box,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  SelectChangeEvent,
} from "@mui/material";
import { GridFilterInputValueProps, GridFilterItem } from "@mui/x-data-grid";
import { MutableRefObject, useState } from "react";
import AutocompleteFilter from "./AutocompleteFilter";
import { GridApiCommunity } from "@mui/x-data-grid/internals";
import {
  CarBrand,
  CarModel,
  CarVersion,
  NamedEntity,
} from "../../../entities/named.entity";

export interface ExtendedGridFilterItem extends GridFilterItem {
  filterType?: string;
  value?: string | number;
}

interface VehicleFilterInputProps
  extends Omit<GridFilterInputValueProps, "item"> {
  item: ExtendedGridFilterItem;
  brands: CarBrand[];
  carModels: CarModel[];
  carVersions: CarVersion[];
  loading: boolean;
  apiRef: MutableRefObject<GridApiCommunity>;
}

export const VehicleFilterInput = ({
  item,
  applyValue,
  brands,
  carModels,
  carVersions,
  loading,
}: VehicleFilterInputProps) => {
  const [filterType, setFilterType] = useState<string>(item.filterType || "");
  const [inputValue, setInputValue] = useState<string>(
    item.value?.toString() || ""
  );

  const filterOptions = [
    { value: "identification", label: "Patente" },
    { value: "brand_id", label: "Marca" },
    { value: "car_model_id", label: "Modelo" },
    { value: "car_version_id", label: "Versión" },
  ];

  const handleFilterTypeChange = (event: SelectChangeEvent) => {
    const newType = event.target.value;
    setFilterType(newType);
    setInputValue("");
    applyValue({
      ...item,
      value: "",
      filterType: newType,
    } as ExtendedGridFilterItem);
  };

  const handleValueChange = (newValue: string | number) => {
    const value = newValue.toString();
    setInputValue(value);
    applyValue({
      ...item,
      value,
    });
  };

  const getFilterData = () => {
    switch (filterType) {
      case "brand_id":
        return { options: brands, loading };
      case "car_model_id":
        return { options: carModels, loading };
      case "car_version_id":
        return { options: carVersions, loading };
      default:
        return { options: [], loading: false };
    }
  };

  const { options, loading: isLoading } = getFilterData();

  const currentFilterOption = filterOptions.find(
    (opt) => opt.value === filterType
  );
  const filterLabel = currentFilterOption
    ? `Seleccionar ${currentFilterOption.label.toLowerCase()}`
    : "";

  return (
    <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
      <InputLabel>Filtrar</InputLabel>
      <Select
        label="Filtrar por"
        size="small"
        value={filterType}
        onChange={handleFilterTypeChange}
        fullWidth
        sx={{ minWidth: "100px" }}
      >
        {filterOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>

      {filterType === "identification" ? (
        <Tooltip title="Presionar Enter para aplicar">
          <TextField
            fullWidth
            label="Buscar por patente"
            sx={{ minWidth: "150px", height: "100%" }}
            variant="standard"
            value={inputValue}
            onChange={(e) => {
              setInputValue(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleValueChange(inputValue);
              }
            }}
            onBlur={() => {
              handleValueChange(inputValue);
            }}
          />
        </Tooltip>
      ) : filterType ? (
        <AutocompleteFilter<NamedEntity>
          label={filterLabel}
          loading={isLoading}
          options={options}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          value={options.find((opt) => opt.name === inputValue) || null}
          onChangeValue={(newValue) => {
            handleValueChange(newValue?.name || "");
          }}
          textFieldVariant="standard"
          textFieldsize="small"
        />
      ) : null}
    </Box>
  );
};
