export interface NamedEntity {
  id: number;
  name: string;
}

export abstract class BaseNamedEntity implements NamedEntity {
  readonly id!: number;
  readonly name!: string;

  constructor(data: Partial<BaseNamedEntity>) {
    Object.assign(this, data);
  }
}

export class CarBrand extends BaseNamedEntity {}
export class CarModel extends BaseNamedEntity {}
export class CarVersion extends BaseNamedEntity {}
export class Provider extends BaseNamedEntity {}
export class Workshop extends BaseNamedEntity {}
