import {
  IconButton,
  Box,
  Typography,
  Alert,
  Stack,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Tabs,
  Tab,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AppBar,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TopPanelBudget from "./TopPanelBudget";
import { useContext, useState } from "react";
import { PurchaseContext } from "../Purchase/context/PurchaseContext";
import WorkshopPurchaseItemList from "../../Purchase/WorkshopPurchaseItemList";
import { WorkshopPurchaseState } from "../../../entities/workshop-purchase-state.entity";
import { WorkshopPurchase } from "../../../entities/workshop-purchase.entity";
import StateProgressBar from "../../Purchase/StateProgressBar";
import History from "../../Purchase/History";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { Budget } from "../../../entities/budget.entity";
import { useDarkMode } from "../../ThemeContext";
import UserAssignment from "./UserAssignment";
import { formatUTCDate } from "../../../utils/dateUtils";
import { TypeKanbanBudgetGroup } from "../KanbanBoard/BudgetKanban/TypeKanbanBudget";

interface ComponentProps {
  selectedBudgetData: TypeKanbanBudgetGroup;
  handleClose: () => void;
  onBudgetChange: (budget: Budget) => void;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ModalDetailBudget({
  selectedBudgetData,
  handleClose,
  onBudgetChange,
}: ComponentProps) {
  const { isDarkMode } = useDarkMode();
  const [value, setValue] = useState(0);
  const [selectedTab, setSelectedTab] = useState(0);

  const { purchases, setPurchases, setSelectedPurchase } =
    useContext(PurchaseContext);

  if (!selectedBudgetData) {
    return null; // O un loader
  }

  const { budgets } = selectedBudgetData; // budgets: TypeKanbanBudget[]
  if (!budgets) {
    return null; // Evitar error si budgets no está
  }

  // selectedBudgetData es TypeKanbanBudgetGroup

  const isMultipleBudgets = budgets.length > 1;

  // Cada budget en budgets es TypeKanbanBudget con originalBudget
  const budget = selectedBudgetData.budgets[selectedTab].originalBudget; // Budget seleccionado según la pestaña

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const purchaseInBudget = purchases.find(
    (purchase) => purchase.budget.id === budget.id
  );

  const isEditionDisabled = [
    WorkshopPurchaseState.CANCELLED_ID,
    WorkshopPurchaseState.EXPIRED_ID,
    WorkshopPurchaseState.REJECTED_ID,
  ].includes(purchaseInBudget?.workshopPurchaseState?.id ?? -1);

  const onPurchaseChange = (purchase: WorkshopPurchase) => {
    setPurchases(
      purchases.map((p) => {
        if (p.id === purchase.id) {
          setSelectedPurchase(purchase);
          return purchase;
        }
        return p;
      })
    );
  };

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const gridContainerStyles = {
    display: "grid",
    gridTemplateAreas: `
    "titel1 titel2 titel3"
    "box1 box2 box3"
    "titel4 titel4 titel5"  
    "box4 box4 box5"
    `,
    gridTemplateColumns: "1fr 1fr 1fr",
    gridTemplateRows: "auto auto auto auto",
    gap: "10px",
    padding: "10px",
    margin: "10px 0px 0px 0px",
    width: "100%",
  };

  const styles = {
    select: {
      backgroundColor: "#2e7d32",
      color: "white",
      textAlign: "center" as const,
    },
    rowCart: {
      width: "100%",
      padding: "6px",
    },
    titleRow: {
      minWidth: "80px",
      display: "flex",
      whiteSpace: "nowrap",
    },
  };

  const styleBgTitelDarkMode = () => {
    switch (true) {
      case isDarkMode:
        return "rgb(64 122 214 / 8%)";
      default:
        return "rgba(214, 20, 50, 0.08)";
    }
  };
  const styleBorderDarkMode = () => {
    switch (true) {
      case isDarkMode:
        return "1px solid #fff";
      default:
        return "#fcdadc";
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: isDarkMode ? "#1C252E" : "#fff",
        overflowY: "scroll",
      }}
    >
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <IconButton
            onClick={() => handleClose()}
            sx={{
              cursor: "pointer",
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            padding: "10px",
          }}
        >
          <TopPanelBudget budget={budget} onBudgetChange={onBudgetChange} />

          <Paper>
            <AppBar
              position="static"
              sx={{ background: styleBgTitelDarkMode(), marginTop: 3.5 }}
            >
              <Box display="grid" gridTemplateColumns="1fr auto">
                {isMultipleBudgets ? (
                  <Tabs
                    value={selectedTab}
                    onChange={handleTabChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="Presupuestos"
                  >
                    {budgets.map((budget, index) => (
                      <Tab
                        key={budget.originalBudget.id}
                        sx={{ padding: 3 }}
                        label={`PR ${budget.originalBudget.id}`}
                        id={`tab-${index}`}
                        aria-controls={`tabpanel-${index}`}
                      />
                    ))}
                  </Tabs>
                ) : (
                  <Box display="flex" alignItems="center" padding={3}>
                    <strong
                      style={{ color: isDarkMode ? "#fff" : "black" }}
                    >{`PR ${budget.id}`}</strong>
                  </Box>
                )}
              </Box>
            </AppBar>
            <Box sx={gridContainerStyles}>
              <Box
                sx={{
                  background: styleBgTitelDarkMode(),
                  border: styleBorderDarkMode(),
                  padding: "3px",
                  borderRadius: "5px",
                  textAlign: "center",
                }}
              >
                <strong>Detalle del presupuesto</strong>
              </Box>

              <Paper sx={{ gridArea: "box1" }}>
                <Box>
                  <Table>
                    <TableBody
                      sx={{
                        display: "flex",
                        border: styleBorderDarkMode(),
                        borderRadius: "5px",
                        height: "100%",
                      }}
                    >
                      <Box sx={{ width: "100%" }}>
                        <TableRow>
                          <TableCell
                            sx={{ padding: "6px", minWidth: "130px" }}
                            scope="row"
                          >
                            <strong>Empresa:</strong>
                          </TableCell>
                          <TableCell sx={styles.rowCart} scope="row">
                            {budget.company.name}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell sx={{ padding: "6px" }} scope="row">
                            <strong>Estado en PGF:</strong>
                          </TableCell>
                          <TableCell sx={styles.rowCart} scope="row">
                            {budget.budgetState?.client_facing_name}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell sx={{ padding: "6px" }} scope="row">
                            <strong>Días de Validez:</strong>
                          </TableCell>
                          <TableCell sx={styles.rowCart} scope="row">
                            {budget.expiration_days}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{ whiteSpace: "nowrap", padding: "6px" }}
                            scope="row"
                          >
                            <strong>En taller:</strong>
                          </TableCell>
                          <TableCell sx={styles.rowCart} scope="row">
                            {budget.in_workshop != true ? "No" : "Si"}
                          </TableCell>
                        </TableRow>
                      </Box>
                    </TableBody>
                  </Table>
                </Box>
              </Paper>

              <Box
                sx={{
                  background: styleBgTitelDarkMode(),
                  padding: "3px",
                  borderRadius: "5px",
                  textAlign: "center",
                  border: styleBorderDarkMode(),
                  gridArea: "titel2",
                }}
              >
                <strong>Solicitud de compra</strong>
              </Box>

              <Paper sx={{ gridArea: "box2" }}>
                <Box>
                  <Table sx={{ height: "100%" }} size="small">
                    <TableBody
                      sx={{
                        display: "flex",
                        border: styleBorderDarkMode(),
                        borderRadius: "5px",
                        height: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-around",
                        }}
                      >
                        <TableRow>
                          <TableCell
                            sx={{ padding: "6px 6px 6px 16px" }}
                            scope="row"
                          >
                            <strong style={styles.titleRow}>ID:</strong>
                          </TableCell>
                          <TableCell sx={styles.rowCart} scope="row">
                            {purchaseInBudget?.id}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{ padding: "6px 6px 6px 16px" }}
                            scope="row"
                          >
                            <strong style={styles.titleRow}>Estado:</strong>
                          </TableCell>

                          <TableCell sx={styles.rowCart} scope="row">
                            {purchaseInBudget?.workshopPurchaseState.name}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{ padding: "6px 6px 6px 16px" }}
                            scope="row"
                          >
                            <strong style={styles.titleRow}>Creación:</strong>
                          </TableCell>
                          <TableCell sx={styles.rowCart} scope="row">
                            {purchaseInBudget
                              ? formatUTCDate(purchaseInBudget.created_at)
                              : "-"}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell
                            sx={{ padding: "6px 6px 6px 16px" }}
                            scope="row"
                          >
                            <strong style={styles.titleRow}>
                              Responsable:
                            </strong>
                          </TableCell>
                          <TableCell sx={styles.rowCart} scope="row">
                            {budget.createdBy.name}
                          </TableCell>
                        </TableRow>
                      </Box>
                    </TableBody>
                  </Table>
                </Box>
              </Paper>

              <Box
                sx={{
                  background: styleBgTitelDarkMode(),
                  padding: "3px",
                  borderRadius: "5px",
                  textAlign: "center",
                  border: styleBorderDarkMode(),
                  gridArea: "titel3",
                }}
              >
                <strong>Fechas</strong>
              </Box>

              <Paper sx={{ gridArea: "box3" }}>
                <Box>
                  <Table sx={{ height: "100%" }} size="small">
                    <TableBody
                      sx={{
                        display: "flex",
                        border: styleBorderDarkMode(),
                        borderRadius: "5px",
                        height: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-around",
                        }}
                      >
                        <TableRow>
                          <TableCell
                            sx={{ padding: "6px 6px 6px 16px" }}
                            scope="row"
                          >
                            <strong style={styles.titleRow}>Creación:</strong>
                          </TableCell>
                          <TableCell sx={styles.rowCart} scope="row">
                            {formatUTCDate(budget.creation_date)}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{ padding: "6px 6px 6px 16px" }}
                            scope="row"
                          >
                            <strong style={styles.titleRow}>Ingreso:</strong>
                          </TableCell>
                          <TableCell sx={styles.rowCart} scope="row">
                            {budget.workshop_arrival_date
                              ? formatUTCDate(budget.workshop_arrival_date)
                              : "-"}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{ padding: "6px 6px 6px 16px" }}
                            scope="row"
                          >
                            <strong style={styles.titleRow}>Turno:</strong>
                          </TableCell>
                          <TableCell sx={styles.rowCart} scope="row">
                            {budget.appointment_date
                              ? formatUTCDate(budget.appointment_date)
                              : "-"}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "6px 6px 6px 16px",
                              border: "none",
                            }}
                            scope="row"
                          >
                            <strong style={styles.titleRow}>Retiro:</strong>
                          </TableCell>
                          <TableCell
                            sx={{
                              width: "100%",
                              padding: "6px",
                              border: "none",
                            }}
                            scope="row"
                          >
                            {budget.workshop_exit_date
                              ? formatUTCDate(budget.workshop_exit_date)
                              : "-"}
                          </TableCell>
                        </TableRow>
                      </Box>
                    </TableBody>
                  </Table>
                </Box>
              </Paper>

              <Box
                sx={{
                  background: styleBgTitelDarkMode(),
                  border: styleBorderDarkMode(),
                  padding: "3px",
                  borderRadius: "5px",
                  textAlign: "center",
                  gridArea: "titel4",
                }}
              >
                <strong>Descripción</strong>
              </Box>

              <Paper sx={{ gridArea: "box4" }}>
                <Box
                  sx={{
                    overflow: "auto",
                    height: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      border: styleBorderDarkMode(),
                      minHeight: "100%",
                      borderRadius: "5px",
                      padding: "5px",
                      maxHeight: "133px",
                      overflow: "auto",
                      wordBreak: "break-word",
                    }}
                  >
                    {budget.description}
                  </Typography>
                </Box>
              </Paper>

              <Box
                sx={{
                  background: styleBgTitelDarkMode(),
                  border: styleBorderDarkMode(),
                  padding: "3px",
                  borderRadius: "5px",
                  textAlign: "center",
                  gridArea: "titel5",
                }}
              >
                <strong>Observaciones</strong>
              </Box>

              <Paper sx={{ gridArea: "box5" }}>
                <Box
                  sx={{
                    overflow: "auto",
                    border: styleBorderDarkMode(),
                    borderRadius: "5px",
                  }}
                >
                  <Table size="small">
                    <TableBody>
                      <Box sx={{ width: "100%" }}>
                        <TableRow>
                          <TableCell
                            sx={{ padding: "6px 6px 6px 16px" }}
                            scope="row"
                          >
                            <strong style={styles.titleRow}>Cliente:</strong>
                          </TableCell>
                          <TableCell sx={styles.rowCart} scope="row">
                            {budget.observations}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{ padding: "6px 6px 6px 16px" }}
                            scope="row"
                          >
                            <strong style={styles.titleRow}>Taller:</strong>
                          </TableCell>
                          <TableCell sx={styles.rowCart} scope="row">
                            {budget.workshop_observations}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{ padding: "6px 6px 6px 16px" }}
                            scope="row"
                          >
                            <strong style={styles.titleRow}>LAX:</strong>
                          </TableCell>
                          <TableCell sx={styles.rowCart} scope="row">
                            {budget.lax_observations}
                          </TableCell>
                        </TableRow>
                      </Box>
                    </TableBody>
                  </Table>
                </Box>
              </Paper>
            </Box>

            <Box>
              <Box
                sx={{
                  marginTop: "10px",
                  borderBottom: 1,
                  borderColor: "divider",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="Asignación de mecanico" {...a11yProps(0)} />
                  <Tab label="Gestión de Turnos" {...a11yProps(1)} />
                  <Tab label="Ingresos / Egresos" {...a11yProps(2)} />
                  <Tab label="Solicitud de Compra" {...a11yProps(3)} />
                  <Tab label="Registros de Trabajo" {...a11yProps(4)} />
                </Tabs>
              </Box>

              <CustomTabPanel value={value} index={0}>
                <UserAssignment budget={budget} />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <Box>Gestión de Turnos</Box>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                <Box>Ingresos / Egresos</Box>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={3}>
                <Box>
                  <Box>
                    {purchaseInBudget ? (
                      <Box>
                        <Stack
                          direction="row"
                          spacing={1}
                          sx={{ margin: "10px 0px 30px 0px" }}
                        >
                          <Alert sx={{ width: "100%" }} severity="info">
                            Los valores son cargados sin IVA
                          </Alert>
                        </Stack>
                        {purchases
                          .filter(
                            (purchase) => purchase.budget.id === budget.id
                          )
                          .sort((a, b) => a.id - b.id)
                          .map((p) => (
                            <Box>
                              <Typography
                                sx={{ color: "gray" }}
                              >{`ID de solicitud de compra: ${p.id} `}</Typography>
                              <strong>Avance de la solicitud</strong>
                              <StateProgressBar
                                states={p.workshopPurchaseItems.map(
                                  (i) => i.workshopPurchaseItemState.name
                                )}
                              />
                              <Box>
                                <WorkshopPurchaseItemList
                                  isEditionDisabled={isEditionDisabled}
                                  onPurchaseChange={onPurchaseChange}
                                  purchase={p}
                                />
                              </Box>
                              <Box sx={{ margin: "15px 0px 15px 0px" }}>
                                <Accordion>
                                  <AccordionSummary
                                    expandIcon={<GridExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                  >
                                    <strong>Actividad</strong>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <Box>
                                      <History purchase={p} />
                                    </Box>
                                  </AccordionDetails>
                                </Accordion>
                              </Box>
                            </Box>
                          ))}
                      </Box>
                    ) : (
                      <Stack
                        direction="row"
                        spacing={1}
                        sx={{ margin: "10px 0px 30px 0px" }}
                      >
                        <Alert sx={{ width: "100%" }} severity="info">
                          Presupuesto sin solicitud de compra
                        </Alert>
                      </Stack>
                    )}
                  </Box>
                </Box>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={4}>
                <Box>Registros</Box>
              </CustomTabPanel>
            </Box>
          </Paper>
        </Box>
      </Box>
    </Box>
  );
}
